<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        {{ text }}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Main',

    data: () => ({
      text: 'Main'
    }),
  }
</script>
